@import 'styles/all_vars';

$bookContentBorder: 2px solid $grey;
$bookContentPadding: 2rem;
$bookContentPaddingMobile: 1.3rem;

$termsPadding: 1rem;
$termNumberSize: 3.5rem;

.checkout {
  .pop-page-outer {
    background-color: $white;
    //padding-top: 2rem;

    @include tablet() {
      background-color: $grey-light;
    }

    @include desktop() {
      //padding-top: 4rem;
    }
  }
}

.checkout__footer {
  padding: $bookContentPaddingMobile 0;
  @include tablet() {
    padding: $bookContentPaddingMobile;
  }

  @include desktop() {
    padding: $bookContentPaddingMobile 0;
  }

  .buttons {
    justify-content: flex-end;
    float: none;
    @include tablet() {
      float: right;
    }
  }

  .button {
    width: 100%;
    letter-spacing: 2px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-weight: $weight-black;
    text-transform: uppercase;

    @include mobile() {
      height: 50px;
    }

    @include tablet() {
      width: 180px;
    }

    .icon {
      position: absolute;
      right: 1rem;
    }

    &.is-outlined {
      color: $black;
    }
  }
}

.checkout__header {
  position: relative;
  overflow: hidden;
  padding-bottom: $bookContentPaddingMobile;

  @include desktop() {
    //padding: $bookContentPadding 0 0;
  }
}

.checkout__title {
  font-size: $size-large;
  font-weight: $weight-black;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  display: none;
  @include desktop() {
    display: flex;
  }
}

.checkout__steps {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-right: 5px;
}

.checkout__menus {
  .label {
    font-size: 1.175rem;
    text-transform: uppercase;
    font-weight: 500;
    color: $black;
    margin-bottom: 0.1rem;
    margin-left: 0 !important;
  }

  .book-menu__view {
    margin-bottom: 1rem;
    display: block;
    cursor: pointer;
    margin-left: 0;
  }
}

.checkout__content {
  background-color: $white;
  font-weight: $weight-bold;
  border: 0;
  min-height: auto;
  width: 100%;
  height: 100%;
  margin: 0;

  @include tablet() {
    border: $bookContentBorder;
    min-height: 520px;
  }

  .field {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .label {
    font-size: 0.9rem;
    font-weight: $weight-medium;
    color: $black;
    margin-left: 8px;
    text-transform: none;
  }
}

.checkout--right-border {
  border: 0;

  @include tablet() {
    border-right: $bookContentBorder;
  }
}

.checkout__content__items {
  padding: 1rem;
  font-weight: $weight-normal;
  line-height: 1.3;
  font-size: 0.9rem;
  @include tablet() {
    padding: 1rem $bookContentPadding 1rem;
  }

  hr {
    height: 1px;
  }

  .title {
    font-weight: $weight-medium;
    margin-bottom: 1rem;
    margin-top: 1rem;
    //margin-left: 1rem;
  }

  @include tablet() {
    padding: 1rem;
  }

  @include widescreen() {
    padding: $bookContentPadding;
  }
}

.checkout--bg-blue {
  background-color: $blue;
  color: $white;

  .title {
    font-size: $size-normal;
    color: $red-dark;
  }
}

.checkout__button {
  z-index: 3;
  //position: fixed;
  //bottom: 0;
  //left: 0;
  //right: 0;
  width: 95%;
  height: auto;
  margin-right: auto;
  margin-left: auto;

  .icon.is-right {
    top: 8px;
    @include tablet() {
      top: 5px;
    }
  }

  @include mobile() {
    margin-bottom: 5px !important;
  }

  @include tablet() {
    height: 51px !important;
  }

  @include tablet() {
    position: relative;
    width: auto;
  }

  &.button--cancel {
    position: relative;
  }

  .icon {
    //top: 5px !important;
  }
}

.checkout-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;

  .book-summary {
    width: 100%;
  }

  .title {
    margin-bottom: 1.3125rem;
  }
}

.terms {
  display: block;
  @include desktop() {
    display: flex;
  }
}

.terms__item {
  width: 100%;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: $termsPadding;
  box-shadow: $box-shadow;
  margin-bottom: 1.5rem + ($termNumberSize * 0.5);
  z-index: 1;
  background: $white;
  text-align: center;
  border-radius: $radius-large;

  @include desktop() {
    width: 50%;
  }

  &:not(:last-child) {
    margin-right: 0;
    @include tablet() {
      margin-right: 1.5rem;
    }
  }
}

.terms__item--gc {
  text-align: left;

  .terms__label {
    font-weight: $weight-bold;
    text-transform: uppercase;
    color: $red-dark;
    margin-bottom: 0.8rem;
  }
}

.terms__number {
  background: $red;
  width: $termNumberSize;
  height: $termNumberSize;
  color: $white;
  border-radius: $radius-rounded;
  font-weight: $weight-black;
  font-size: $size-medium;
  margin: -(($termNumberSize * 0.5) + $termsPadding) auto $termsPadding auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.line-items__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.checkout__footer {
  @include mobile() {
    margin-bottom: 3rem;
    padding-bottom: 0;
  }

  .buttons {
    @include mobile() {
      padding: 1rem;
    }

    button.is-huge {
      @include mobile() {
        margin-bottom: 5px;
      }
    }
  }
}

.buttons {
  .button--cancel {
    &:not(:last-child):not(.is-fullwidth) {
      margin-right: 0;
      @include tablet() {
        margin-right: 0.5rem;
      }
    }
  }
}

.button--cancel {
  display: block;
  width: 100%;
  @include tablet() {
    width: auto;
    display: inline-block;
  }
}

.checkout__invalid {
  &.select,
  .select {
    &:not(.disabled) {
      border-color: $red;
    }
  }

  .book-menu {
    .select:not(.disabled) {
      select {
        border-color: $red;
      }
    }
  }
}

.book-menu--disabled {
  color: $grey-dark;

  .pprice__tag--sale {
    color: $white;
    background-color: $grey-dark;
  }

  button,
  .button {
    user-select: none;
  }
}

#checkout-giftcard,
#checkout-page {
  margin-top: 1rem;
  @include tablet() {
    margin-top: 2rem;
  }
}

#checkout-page {
  position: relative;
  z-index: 20;

  .section-product-page {
    padding-top: 0;
  }
}

body,
html {
  background-color: $white;
  @include tablet() {
    background-color: $grey-light;
  }
}
