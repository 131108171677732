@import '../../../../styles/all_vars.scss';

#checkout-giftcard {
  .steps {
    width: 100%;
    @include desktop() {
      max-width: 460px;
      float: right;
    }
  }

  .step-fields {
    width: 100%;
    max-width: 100%;

    @include tablet() {
      max-width: 600px;
    }

    .field {
      width: 100%;
    }
  }

  .step-fields-grid {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 4rem;

    @include tablet() {
      display: flex;
    }

    @include desktop() {
      display: flex;
      max-width: 600px;
    }

    .field {
      display: block;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;

      &:not(:last-child) {
        padding-right: 0;
        @include tablet() {
          padding-right: 0.75rem;
        }
      }

      @include tablet() {
        width: 50%;
      }
    }
  }
}

.checkout__content__summary {
  @include mobile() {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 5rem;
    border-radius: $radius-large;
  }
}

.checkout__footer {
  @include mobile() {
  }
}
